import PropTypes from 'prop-types'
import { Box } from '@mui/material'
import styles from './associatedMedia.styles'

const mediaLogoPropTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    imageUrl: PropTypes.string,
  }),
}

const MediaLogo = ({ data }) => {
  return (
    <Box sx={styles.media} className='logo' key={data.id}>
      <img src={data.imageUrl} alt={data.name} />
    </Box>
  )
}

MediaLogo.propTypes = mediaLogoPropTypes

export default MediaLogo
