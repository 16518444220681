import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import styles from './associatedMedia.styles'

const mediaButtonPropTypes = {
  onClick: PropTypes.func,
  data: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    imageUrl: PropTypes.string,
  }),
}

const MediaButton = ({ onClick, data }) => {
  return (
    <Button
      sx={styles.media}
      className='button'
      key={data.id}
      onClick={() => onClick(data)}
    >
      <img src={data.imageUrl} alt={data.name} />
    </Button>
  )
}

MediaButton.propTypes = mediaButtonPropTypes

export default MediaButton
