import PropTypes from 'prop-types'
import { Box } from '@mui/material'

import { AssociatedMediaData } from '../../propTypes'
import MediaButton from './MediaButton'
import MediaLogo from './MediaLogo'
import styles from './associatedMedia.styles'

const associatedMediaPropTypes = {
  media: PropTypes.arrayOf(AssociatedMediaData),
  onClickMedia: PropTypes.func,
}

const AssociatedMedia = ({ media = [], onClickMedia }) => {
  const MediaComponent = !!onClickMedia ? MediaButton : MediaLogo
  return (
    <Box sx={styles.root}>
      {media.map((mediaData, i) => (
        <MediaComponent
          key={mediaData?.id ?? i}
          onClick={onClickMedia}
          data={mediaData}
        />
      ))}
    </Box>
  )
}

AssociatedMedia.propTypes = associatedMediaPropTypes

export default AssociatedMedia
